<template>
  <div>
    <div class="select-file">
      <el-radio v-model="uploadData.convertType" label="word">Word</el-radio>
      <el-radio v-model="uploadData.convertType" label="excel">Excel</el-radio>
      <el-radio v-model="uploadData.convertType" label="image">图片</el-radio>
      <el-upload
        :action="config.baseUrl + 'document/pdfconvert'"
        :data="uploadData"
        accept=".pdf"
        :file-list="fileList"
        :show-file-list="false"
        :on-progress="progress"
        :on-success="success"
        :before-upload="beforeAvatarUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">文件大小不得超过1GB，完成后会自动下载生成后的文档，若完成后没有自动下载，请点击下载按钮进行下载，或者复制链接，在新页面地址栏中粘贴链接后按回车键下载</div>
      </el-upload>
    </div>
    <div v-if="fileName.length > 0">
      <p v-for="item in fileName" :key="item" class="break-all-show">{{item}}</p>
      <template v-if="uploadData.convertType == 'image'">
        <div>共有{{fileName.length}}张图片</div>
        <el-button v-for="(item, index) in fileName" :key="index" size="small" type="primary" @click="downFile(index)">下载图片{{index+1}}</el-button>
      </template>
      <el-button v-else size="small" type="primary" @click="downFile(0)">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadData: {
        catalog: 'test/qin',
        convertType: 'word'
      },
      fileList: [],
      transcodeState: 0, //0: 无任务，1：转换中，2：转换成功
      fileName: [],
      interval: null, //轮询查询结果定时器
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$emit('loadChange', false)
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt1G = file.size / 1024 / 1024 / 1024 < 1;
      if (!isLt1G) {
        this.$message.error('大小不能超过1GB!');
      }
      return isLt1G;
    },
    progress() {
      this.$emit('loadChange', true)
      this.fileName = []
      this.transcodeState = 1
    },
    success(res) {
      this.interval = setInterval(async () => {
        await this.queryJob(res.data.jobId)
      }, 5000)
    },
    async queryJob(jobId) {
      await this.$api.post('document/queryjob', {jobId}).then(res => {
        if (res.data.completed) {
          if(res.data.data && res.data.data[0].url) {
            this.transcodeState = 2
            this.fileName = res.data.data.map(m => {
              return m.url.replace('http', 'https')
            })
            if (this.fileName.length == 1) {
              this.downFile(0)
            }
          } else {
            this.transcodeState = 2
            this.$notify.error({
              title: '失败',
              message: res.data.message,
              duration: 0
            });
          }
          clearInterval(this.interval)
          this.$emit('loadChange', false)
        }
      })
    },
    downFile(index) {
      window.open(this.fileName[index])
    },
  }
}
</script>

<style lang="scss" scoped>
.select-file {
  ::v-deep .el-select {
    margin-bottom: 10px;
    width: 130px;
  }
}
</style>