import axios from "axios";
import { errHandle } from "../utils/utils";
import config from '../config/config'

import context from "../main"

let baseURL = config.baseUrl;

let apiConfig = {
  baseURL: baseURL,
  timeout: 30000, //设置最大请求时间
};
const _axios = axios.create(apiConfig);

// _axios.defaults.headers.common["token"] = getToken();

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  (apiConfig) => {
    let token = context.$store.getters.getToken
    if (token) {
      apiConfig.headers['token'] = token
    }
    return apiConfig;
  },
  (err) => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
  (res) => {
    if (res.data.code == 2) {
      context.$store.dispatch('del_token')
      context.$router.push('/login')
    }
    if (res.headers['token']) {
      context.$store.dispatch('set_token', res.headers['token'])
    }
    return res;
  },
  (err) => {
    if (err) {
      errHandle(err);
    }
    return Promise.reject(err);
  }
);

//封装post,get方法
const api = {
  get(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "GET",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default api;
