<template>
  <div class="version">
    <el-radio v-model="type" label="add" @change="typeChange">发布新版本</el-radio>
    <el-radio v-model="type" label="edit" @change="typeChange">编辑旧版本</el-radio>
    <el-input v-if="type == 'add'" class="number" v-model="number" placeholder="请输入版本号"></el-input>
    <el-select v-else class="select-num" v-model="editVersion" @change="editChange" placeholder="请选择版本">
      <el-option
        v-for="item in versionList"
        :key="item.number"
        :label="item.number"
        :value="item.number">
      </el-option>
    </el-select>
    <div class="editor-box">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 330px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </div>
    <el-button v-if="type == 'add'" type="primary" class="submit-btn" @click="submitVersion">发布</el-button>
    <el-button v-else type="primary" class="submit-btn" @click="editSubmit">提交</el-button>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      number: '',
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      type: 'add',
      editVersion: '',
      versionList: []
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.getVersionList()
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    submitVersion() {
      if (!this.number) {
        this.$message.error('请输入版本号')
        return
      }
      this.$confirm('请确认是否发布新版本', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          number: this.number,
          content: this.editor.getHtml()
        }
        this.$api.post('user/releaseVersion', params).then(res => {
          if (res.code == 0) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    },
    getVersionList() {
      this.$api.post('/user/getVersionList').then(res => {
        this.versionList = res.data
      })
    },
    typeChange() {
      this.editor.setHtml('')
      this.editVersion = ''
    },
    editChange() {
      const version = this.versionList.find(f => f.number == this.editVersion)
      this.editor.setHtml(version.content)
    },
    editSubmit() {
      if (!this.editVersion) {
        this.$message.error('请先选择版本号')
        return
      }
      this.$confirm('请确认是否提交修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          number: this.editVersion,
          content: this.editor.getHtml()
        }
        this.$api.post('user/editVersion', params).then(res => {
          if (res.code == 0) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.version {
  position: relative;
}
.number {
  margin-bottom: 10px;
  width: auto;
}
.select-num {
  margin-bottom: 10px;
}
.editor-box {
  border: 1px solid #ccc;
}
.submit-btn {
  margin-top: 10px;
  width: 100%;
}
</style>