<template>
  <div>
    <div>tool</div>
  </div>  
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>