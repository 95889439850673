<template>
  <div>
    <div class="select-file">
      <el-radio v-model="uploadData.convertType" label="jpg">jpg</el-radio>
      <el-radio v-model="uploadData.convertType" label="png">png</el-radio>
      <el-radio v-model="uploadData.convertType" label="webp">webp</el-radio>
      <el-radio v-model="uploadData.convertType" label="bmp">bmp</el-radio>
      <el-radio v-model="uploadData.convertType" label="gif">gif</el-radio>
      <el-radio v-model="uploadData.convertType" label="tiff">tiff</el-radio>
      <el-radio v-model="uploadData.convertType" label="heic">heic</el-radio>
      <el-radio v-model="uploadData.convertType" label="avif">avif</el-radio>
      <el-upload
        :action="config.baseUrl + 'image/convert'"
        :data="uploadData"
        accept=".jpg,.jpeg,.png,.bmp,.gif"
        :file-list="fileList"
        :show-file-list="false"
        :on-progress="progress"
        :on-success="success"
        style="margin-top: 20px"
        :before-upload="beforeAvatarUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">文件大小不得超过20MB，完成后会自动下载生成后的文档，若完成后没有自动下载，请点击下载按钮进行下载，或者复制链接，在新页面地址栏中粘贴链接后按回车键下载</div>
      </el-upload>
    </div>
    <div v-if="fileName.length > 0">
      <p v-for="item in fileName" :key="item" class="break-all-show">{{item}}</p>
      <el-button size="small" type="primary" @click="downFile(0)">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadData: {
        catalog: 'test/qin',
        convertType: 'jpg'
      },
      fileList: [],
      transcodeState: 0, //0: 无任务，1：转换中，2：转换成功
      fileName: [],
      interval: null, //轮询查询结果定时器
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$emit('loadChange', false)
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt1G = file.size  / 1024 / 1024 < 20;
      if (!isLt1G) {
        this.$message.error('大小不能超过20MB!');
      }
      return isLt1G;
    },
    progress() {
      this.$emit('loadChange', true)
      this.fileName = []
      this.transcodeState = 1
    },
    success(res) {
      console.log(res)
      this.$emit('loadChange', false)
      this.fileName = res.requestUrls
      if (this.fileName.length == 1) {
        this.downFile(0)
      }
      // this.interval = setInterval(async () => {
      //   await this.queryJob(res.data.jobId)
      // }, 5000)
    },
    downFile(index) {
      window.open(this.fileName[index])
    },
  }
}
</script>

<style lang="scss" scoped>
</style>