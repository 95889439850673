<template>
  <div class="home" v-loading="homeLoading">
    <img class="bg-img" :src="bgSrc" width="100%" height="100%"/>
    <div ref="content" class="content">
      <div class="home-app">
        <div>
          <el-popover
            placement="right"
            width="150"
            trigger="click">
            <el-button v-if="userName" type="text" disabled style="color: #000">{{userName}}</el-button>
            <el-button type="text" @click="exit">退出登录</el-button>
            <div class="home-app-box" slot="reference">
              <img src="https://fileoss.qinxiang.cc/system/appicon/home.png"/>
              <p>开始</p>
            </div>
          </el-popover>
        </div>
        <div v-for="app in allApps" :key="app.key" @click="openApp(app.key)">
          <div class="home-app-box">
            <img :src="app.icon"/>
            <p>{{app.name}}</p>
          </div>
        </div>
        <!-- <div @click="exit">
          <el-tooltip effect="dark" content="退出系统" placement="right">
            <div class="home-app-box">
              <img src="https://fileoss.qinxiang.cc/system/appicon/exit.png"/>
              <p>退出系统</p>
            </div>
          </el-tooltip>
        </div> -->
      </div>
      <app @appClick="appClick" @closeApp="closeApp" v-for="app in openApps" :key="app.key" :app="app" :contentSize="contentSize" :isMobile="isMobile"></app>
    </div>
    <div class="bottom">
      <div class="bottom-app" id="home">
        <el-popover
          placement="top"
          width="150"
          trigger="click">
          <el-button v-if="userName" type="text" disabled style="color: #000">{{userName}}</el-button>
          <el-button type="text" @click="exit">退出登录</el-button>
          <el-tooltip slot="reference" effect="dark" content="开始" placement="left">
            <img  src="https://fileoss.qinxiang.cc/system/appicon/home.png"/>
          </el-tooltip>
        </el-popover>
      </div>
      <div id="tools">
        <div v-for="app in allApps" :key="app.key" class="bottom-app" @click="openApp(app.key)">
          <el-tooltip effect="dark" :content="app.name" placement="top">
            <img :src="app.icon"/>
          </el-tooltip>
        </div>
      </div>
      
      <!-- <div class="bottom-app" @click="exit">
        <el-tooltip effect="dark" content="退出系统" placement="top">
          <img src="https://fileoss.qinxiang.cc/system/appicon/exit.png"/>
        </el-tooltip>
      </div> -->
    </div>
    <el-dialog
      title="新版本"
      :visible.sync="versionDialog"
      custom-class="version-dialog"
      top="50px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :width="isMobile ? '300px' : '400px'">
      <div v-if="versionInfo" v-html="versionInfo.content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="start">我已知晓，开始体验</el-button>
      </span>
    </el-dialog>
  </div>  
</template>

<script>
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import app from '../app/app'
export default {
  components: { app },
  data() {
    return {
      openApps: [],
      allApps: [],
      contentSize: {
        width: 0,
        height: 0,
      },
      maxZIndex: 1,
      bgSrc: 'https://fileoss.qinxiang.cc/system/bg.jpg',
      isMobile: false,
      mobileStyle: {top: 0,left: 0,width: '100%',height: '100%',borderRadius: 0},
      homeLoading: false,
      userName: '',
      versionDialog: false,
      versionInfo: null,
    }
  },
  created() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile(); // 初始化时进行一次判断
    this.getAppList()
    this.getNewVersion()
  },
  mounted() {
    this.contentSize.width = this.$refs.content.clientWidth
    this.contentSize.height = this.$refs.content.clientHeight
    if (this.$store.state.userInfo && this.$store.state.userInfo.name) {
      this.userName = this.$store.state.userInfo.name
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    getAppList() {
      this.homeLoading = true
      this.$api.post('app/list').then(res => {
        if (res.code == 0) {
          this.allApps = res.data.map(m => {
            return {
              ...m,
              style: this.isMobile ? JSON.parse(JSON.stringify(this.mobileStyle)) : JSON.parse(m.style)
            }
          })
        }
        this.homeLoading = false
      })
    },
    openApp(key) {
      let item = this.openApps.find(f => f.key == key)
      if (item) {
        item.style.visibility = 'visible'
        if (this.isMobile) {
          this.openApps.forEach(e => {
            if (e.key != key) {
              this.$set(e.style, 'visibility', 'hidden')
            }
          })
        } else {
          this.appClick(key)
        }
      } else {
        let app = this.allApps.find(f => f.key == key)
        if (this.isMobile) {
          app.style.top = 0
          app.style.left = 0
        } else {
          let { top, left } = this.getNewAppPosition('10%', '10%')
          app.style.top = top
          app.style.left = left
        }
        app.style.zIndex = ++this.maxZIndex
        this.openApps.push(app)
      }
      console.log(this.openApps)
    },
    addApp() {
      console.log('addApp')
      let index = this.openApps.length
      let { top, left } = this.getNewAppPosition('10%', '10%')
      if (this.openApps.length < this.allApps.length) {
        const app = this.allApps[index]
        app.style.top = top
        app.style.left = left
        this.openApps.push(this.allApps[index])
      }
    },
    appClick(key) {
      let app = this.openApps.find(f => f.key == key)
      this.$set(app.style, 'zIndex', ++this.maxZIndex)
      this.$forceUpdate()
    },
    closeApp(key) {
      const index = this.openApps.findIndex(f => f.key == key)
      this.openApps.splice(index, 1)
    },
    getNewAppPosition(top, left) {
      let newTop = top
      let newLeft = left
      for(let i=0; i<this.openApps.length; i++) {
        let equalItem = this.openApps.find(f => f.style.top == newTop && f.style.left == newLeft)
        if (equalItem) {
          newTop = parseInt(newTop) + 3 + '%'
          newLeft = parseInt(newLeft) + 3 + '%'
        }
      }
      return { top: newTop, left: newLeft }
    },
    checkLogin() {
      if (this.$store.state.token) {
        this.$api.post('user/checkToken').then(res => {
          if (res.code == 2) {
            this.$message.error(res.msg)
            this.$router.push('/login')
          }
        })
      } else {
        this.$router.push('/login')
      }
    },
    exit() {
      this.$confirm('请确认是否要退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('del_token')
        this.$router.push('/login')
      }).catch(() => {
        this.checkLogin()
      })
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 767
    },
    getNewVersion() {
      this.$api.post('/user/getNewVersion').then(res => {
        if (res.code == 0 && res.data) {
          if (!this.$store.state.userInfo.lastVersion || this.$store.state.userInfo.lastVersion != res.data.number) {
            this.versionInfo = res.data
            this.versionDialog = true
          }
        }
      })
    },
    start() {
      this.versionDialog = false
      if (!this.$store.state.userInfo.lastVersion) {
        this.setDrive()
      } else {
        this.$api.post('/user/startVersion', {version: this.versionInfo.number})
      }
    },
    setDrive() {
      const driverObj = driver({
        showProgress: true,
        nextBtnText: '下一步',
        prevBtnText: '上一步',
        doneBtnText: '我学会了，开始吧',
        allowClose: false,
        disableActiveInteraction: true,
        steps: [
          { 
            element: '#tools', 
            popover: { 
              title: '工具栏', 
              description: '这里是工具栏，包含各种小工具，点击即可打开。分别为我的文件、PDF转码、音频转MP3、图片转文档、图片转码。桌面左侧图标功能与此相同。' 
            } 
          },
          { 
            element: '.app-header', 
            popover: { 
              title: '工具窗口', 
              description: `点击工具会打开一个工具窗口，工具窗口顶部右侧有三个按钮，分别可以进行最小化、最大化(仅PC端)和关闭操作。
              鼠标在窗口顶部的中间空白区域按住移动还可以进行拖拽工具窗口。`
            }
          },
          { 
            element: '#home', 
            popover: { 
              title: '退出系统', 
              description: '点击这里可以选择退出登录。' 
            } 
          },
        ],
        onNextClick: (element, step) => {
          switch(step.element) {
            case '#tools': 
              this.openApp('file');
              this.$nextTick(() => {
                driverObj.moveNext()
              });
              break;
            case '.app-header': this.openApps = []; driverObj.moveNext(); break;
            case '#home': this.$api.post('/user/startVersion', {version: this.versionInfo.number}); driverObj.moveNext(); break;
            default: driverObj.moveNext(); break;
          }
        },
        onPrevClick: (element, step) => {
          switch(step.element) {
            case '.app-header': this.openApps = []; driverObj.movePrevious(); break;
            case '#home': 
              this.openApp('file'); 
              this.$nextTick(() => {
                driverObj.movePrevious()
              }); 
              break;
            default: driverObj.movePrevious(); break;
          }
        }
      });
      driverObj.drive();
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .bg-img {
    width:100%;  
    height:100%;
    z-index:-1;
    position: absolute;
  }
  .content {
    position: relative;
    height: calc(100% - 60px);
    // background: aquamarine;
    overflow: hidden;
  }
  .bottom {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    height: 40px;
    background: rgba($color: #fff, $alpha: 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 10px;
    .bottom-app {
      width: 40px;
      height: 40px;
      // line-height: 60px;
      cursor: pointer;
      text-align: center;
      padding: 0 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.home-app {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  z-index: -1;
  height: 100%;
  &>div {
    // width: 70px;
    padding: 5px;
    cursor: pointer;
  }
  .home-app-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  p {
    font-size: 13px;
    color: #fff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
#tools {
  display: flex;
}
.home {
  ::v-deep .version-dialog .el-dialog__body {
    padding: 0 20px;
  }
}
</style>