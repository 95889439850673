<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="待审核" name="apply">
      <apply-list v-if="activeName == 'apply'"></apply-list>
    </el-tab-pane>
    <el-tab-pane label="审核列表" name="all">
      <all-apply-list v-if="activeName == 'all'"></all-apply-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import applyList from './components/applyList'
import allApplyList from './components/allApplyList'
export default {
  components: { applyList, allApplyList },
  data() {
    return {
      activeName: 'apply',
    }
  },
}
</script>

<style lang="scss" scoped>

</style>