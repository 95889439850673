<template>
  <div>
    <el-table :data="applyData" show-overflow-tooltip style="width: 100%">
      <el-table-column prop="name" label="姓名" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="account" label="账号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="pass(scope.row)">通过</el-button>
          <el-button type="text" size="mini" @click="fail(scope.row)">不通过</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="审核不通过"
      append-to-body
      :visible.sync="reasonDialog"
      width="30%">
      <el-input v-model="reason" placeholder="请输入审核不通过原因"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reasonDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitReason">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyData: [],
      reason: '',
      reasonDialog: false,
      failInfo: {}
    }
  },
  created() {
    this.getApplyData()
  },
  methods: {
    getApplyData() {
      this.applyData = []
      this.$api.post('user/applyList').then(res => {
        if (res.code == 0) {
          this.applyData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    pass(row) {
      this.$api.post('/user/applyPass', {
        id: row.id, 
        account: row.account,
        email: row.email,
      }).then(res => {
        if (res.code == 0) {
          this.getApplyData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    fail(row) {
      this.failInfo = row
      this.reason = ''
      this.reasonDialog = true
    },
    submitReason() {
      if (!this.reason) {
        this.$message.error('请先填写审批不通过原因')
        return
      }
      this.$api.post('/user/applyFail', {
        id: this.failInfo.id, 
        reason: this.reason, 
        account: this.failInfo.account,
        email: this.failInfo.email,
      }).then(res => {
        if (res.code == 0) {
          this.getApplyData()
          this.reasonDialog = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>