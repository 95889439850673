<template>
  <div>
    <el-upload
      :action="config.baseUrl + 'transcode/submitjob'"
      :data="uploadData"
      accept=".wav,.pcm,.tta,.flac,.au,.ape,.mp3,.wma,.ogg,.aac,.ra,.midi,.mpc,.mv,.aif,.aiff,.m4a,.mka,.mp2,.mpa,.wv,.ac3,.dts,.amr,.3gpp"
      :file-list="fileList"
      :show-file-list="false"
      :on-progress="progress"
      :on-success="success"
      :before-upload="beforeAvatarUpload">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">目前仅支持wav、pcm、tta、flac、au、ape、mp3、wma、ogg、aac、ra、midi、mpc、mv、aif、aiff、m4a、mka、mp2、mpa、wv、ac3、dts、amr、3gpp格式，且大小不超过1GB</div>
    </el-upload>
    <div v-if="fileName">
      <p class="break-all-show">转码后地址：{{fileName}}</p>
      <div>
        <video controls ref="audio" name="media" width="300px" height="60px">
          <source :src="fileName" type="audio/mpeg"/>
        </video>
      </div>
      <!-- <el-button size="small" type="primary" @click="showFile">试听</el-button> -->
      <el-button size="small" type="primary" @click="downFile">下载</el-button>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://gosspublic.alicdn.com/aliyun-oss-sdk-6.18.0.min.js"></script>
<script>
import OSS from 'ali-oss'
export default {
  data() {
    return {
      uploadData: {
        catalog: 'test/qin'
      },
      fileList: [],
      transcodeState: 0, //0: 无任务，1：转换中，2：转换成功
      fileName: '',
      interval: null, //轮询查询结果定时器
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$emit('loadChange', false)
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt1G = file.size / 1024 / 1024 / 1024 < 1;
      if (!isLt1G) {
        this.$message.error('大小不能超过1GB!');
      }
      return isLt1G;
    },
    progress() {
      this.$emit('loadChange', true)
      this.fileName = ''
      this.transcodeState = 1
    },
    success(res) {
      this.interval = setInterval(async () => {
        await this.queryJob(res.data.jobId)
        if (this.transcodeState == 2) {
          clearInterval(this.interval)
        }
      }, 1000)
    },
    async queryJob(jobId) {
      await this.$api.post('transcode/queryjob', {jobId}).then(res => {
        if (res.data && res.data.url) {
          this.transcodeState = 2
          this.fileName = res.data.url
          this.$emit('loadChange', false)
        }
      })
    },
    showFile() {
      window.open(this.fileName)
    },
    downFile() {
      const arr = this.fileName.split('//')
      const fileArr = arr[1].split('/')
      fileArr.splice(0,1)
      const fileName = fileArr[fileArr.length - 1]
      const file = fileArr.join('/')
      const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAI5tBx2LmAirhodndr6Jnh",
        accessKeySecret: "PvEhhNbYsRczA5fqHp45gbGkeVCQ9j",
        bucket: "qin-file",
      });
      const response = {
        "content-disposition": `attachment; filename=${fileName}`,
      };
      const url = client.signatureUrl(file, { response });
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>