<template>
  <div ref="app" class="app" :style="app.style" @click="appClick">
    <div class="app-header">
      <span class="app-name" ref="name">{{app.name}}</span>
      <div class="app-operate" @mousedown="appDown" @mouseup="appUp" @mousemove="appMove" @mouseout="appOut"></div>
      <div class="header-tools">
        <i class="el-icon-remove" title="最小化" @click.stop="cancelApp"></i>
        <i v-if="!this.isMobile" class="el-icon-circle-plus" title="最大化" @click.stop="maxApp"></i>
        <i class="el-icon-error" title="关闭" @click.stop="closeApp"></i>
      </div>
    </div>
    <div class="app-content" v-loading="pageLoading">
      <component :is="app.component" @loadChange="loadChange"></component>
    </div>
  </div>
</template>

<script>
import tool from '../tool/tool'
import audioTool from '../tool/audioTool'
import imgConvert from '../tool/imgConvert'
import imgDocTool from '../tool/imgDocTool'
import pdfTool from '../tool/pdfTool'
import file from '../file/file'
import manage from '../manage/manage'
import version from '../version/version'
export default {
  components: { tool, audioTool, imgConvert, imgDocTool, pdfTool, file, manage, version },
  props: {
    app: {
      type: Object,
      default: () => {
        return {}
      } 
    },
    contentSize: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMove: false,
      offsetX: 0,
      offsetY: 0,
      nameOffsetWidth: 0,
      appHeight: 0,
      pageLoading: false,
    }
  },
  mounted() {
    this.appHeight = this.$refs.app.clientHeight
  },
  methods: {
    loadChange(state) {
      this.pageLoading = state
    },
    appClick() {
      this.$emit('appClick', this.app.key)
    },
    cancelApp() {
      this.$set(this.app.style, 'visibility', 'hidden')
    },
    maxApp() {
      if (this.app.isMax) {
        this.$set(this.app.style, 'width', this.app.style.oldWidth)
        this.$set(this.app.style, 'height', this.app.style.oldHeight)
        this.$set(this.app.style, 'top', this.app.style.oldLeft)
        this.$set(this.app.style, 'left', this.app.style.oldTop)
        this.$set(this.app, 'isMax', false)
      } else {
        this.app.style.oldWidth = this.app.style.width
        this.app.style.oldHeight = this.app.style.height
        this.app.style.oldLeft = this.app.style.left
        this.app.style.oldTop = this.app.style.top
        this.$set(this.app.style, 'oldWidth', this.app.style.width)
        this.$set(this.app.style, 'oldHeight', this.app.style.height)
        this.$set(this.app.style, 'oldLeft', this.app.style.left)
        this.$set(this.app.style, 'oldTop', this.app.style.top)
        this.$set(this.app.style, 'width', '100%')
        this.$set(this.app.style, 'height', '100%')
        this.$set(this.app.style, 'top', 0)
        this.$set(this.app.style, 'left', 0)
        this.$set(this.app, 'isMax', true)
      }
    },
    appDown(e) {
      this.isMove = true
      this.offsetX = e.offsetX
      this.offsetY = e.offsetY
      this.nameOffsetWidth = this.$refs.name.offsetWidth
    },
    appUp(e) {
      this.isMove = false
    },
    appOut(e) {
      this.isMove = false
    },
    closeApp() {
      this.$confirm('请确认是否要关闭'+this.app.name+'?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('closeApp', this.app.key)
      }).catch(() => {})
    },
    appMove(e) {
      if (this.isMove) {
        this.$set(this.app.style, 'left', (e.clientX - this.nameOffsetWidth - this.offsetX - 10 > 0 ? e.clientX - this.offsetX - this.nameOffsetWidth - 10 : 0) + 'px')
        this.$set(this.app.style, 'top', (e.clientY - this.offsetY > 0 ? e.clientY - this.offsetY : 0) + 'px')
        if (parseInt(this.app.style.top) > this.contentSize.height - 50) {
          this.$set(this.app.style, 'top', this.contentSize.height - 50 + 'px')
        }
        if (parseInt(this.app.style.left) > this.contentSize.width - 180) {
          this.$set(this.app.style, 'left', this.contentSize.width - 180 + 'px')
        }
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  position: absolute;
  background: #ffff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border: 1px solid #ebeef5;
  overflow: hidden;
  max-height: 100%;
  .app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 10px;
    background: #eee;
    .app-name {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .app-operate {
    flex: 1;
    height: 100%;
    cursor: all-scroll;
  }
  .header-tools {
    font-size: 22px;
    i {
      margin-right: 5px;
      cursor: pointer;
      &:first-child {
        color: #409EFF;
      }
      &:nth-child(2) {
        color: #E6A23C;
      }
      &:nth-child(3) {
        color: #F56C6C;
      }
    }
  }
  .app-content {
    height: calc(100% - 35px);
    box-sizing: border-box;
    padding: 10px;
    overflow-y: auto;
  }
}
</style>