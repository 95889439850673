<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
    }
  },
  created() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile(); // 初始化时进行一次判断
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 767
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.break-all-show {
  word-break: break-all;
}
.el-dialog {
  min-width: 300px !important;
}
#app ~ .el-popover {
  text-align: center;
}
@media screen and (max-width: 420px) {
  .el-message-box {
    width: 100% !important;
    border-radius: 0 !important;
  }
  .el-message {
    top: 0 !important;
    width: 200px !important;
  }
}
</style>
