import Vue from 'vue'
import Vuex from 'vuex'

import vuexalong from 'vuex-along'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
  },
  getters: {
    getToken: (state) => {return state.token},
    getUserInfo: (state) => {return state.userInfo}
  },
  mutations: {
    set_token(state,token) {
      state.token = token;
    },
    del_token(state) {
      state.token = '';
      state.userInfo = {};
    },
    set_userInfo(state,data) {
      state.userInfo = data;
    },
    del_userInfo() {
      state.userInfo = {};
    }
  },
  actions: {
    set_token(context,token) {
      context.commit("set_token",token);
    },
    del_token(context){
      context.commit("del_token");
    },
    set_userInfo(context, data) {
      context.commit("set_userInfo",data);
    },
    del_userInfo(context) {
      context.commit("del_userInfo")
    }
  },
  plugins: [
    vuexalong({
      name: 'hellow-vue-along',
      session: {
        list: ['hotalname', 'collapsed', 'jwt']
      }
    })
  ]
})
