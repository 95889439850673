<template>
  <div>
    <el-table :data="applyData" style="width: 100%">
      <el-table-column prop="name" label="姓名" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="account" label="账号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
      <el-table-column prop="state" label="审核状态" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{stateMap[scope.row.state]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="不通过原因" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyData: [],
      stateMap: {
        0: '待审核',
        1: '通过',
        2: '未通过'
      }
    }
  },
  created() {
    this.getAllApply()
  },
  methods: {
    getAllApply() {
      this.applyData = []
      this.$api.post('user/allApply').then(res => {
        if (res.code == 0) {
          this.applyData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>