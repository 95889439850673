<template>
  <div class="file">
    <div class="dir" v-if="currentDir">
      <div>当前目录：{{currentDir}}</div>
    </div>
    <!-- <div v-else>
      <el-input v-model="user" placeholder="请输入用户名" size="medium"></el-input>
      <el-button size="medium" type="primary" class="in-btn" @click="getList">进入我的文件</el-button>
    </div> -->
    <div class="btn-group" v-if="currentDir">
      <el-button :disabled="currentDir.lastIndexOf('/') == -1" size="mini" type="primary" class="m-r-10" @click="returnBack">返回上一级</el-button>
      <el-upload
        :action="config.baseUrl + 'file/put'"
        :data="uploadData"
        :show-file-list="false"
        :on-progress="progress"
        :on-success="putSuccess"
        :before-upload="beforeAvatarUpload">
        <el-button size="mini" type="primary" class="m-r-10">上传文件</el-button>
        <!-- <div slot="tip" class="el-upload__tip">文件大小不能超过5GB</div> -->
      </el-upload>
      <el-button size="mini" type="primary" @click="addFolder">新建目录</el-button>
      <el-button size="mini" type="primary" @click="getList">刷新</el-button>
    </div>
    <div class="file-list" v-if="currentDir">
      <el-table :data="list" style="width: 100%" empty-text="当前目录暂无文件" size="mini">
        <el-table-column prop="fileName" label="名称"></el-table-column>
        <el-table-column prop="lastModified" label="修改日期" width="140"></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button v-if="scope.row.type != 'folder'" type="text" size="mini" @click="rename(scope.row)">重命名</el-button>
            <el-button v-if="scope.row.type != 'folder'" class="m-r-10" type="text" size="mini" @click="downLoad(scope.row)">下载</el-button>
            <el-button v-if="scope.row.type == 'folder'" class="m-r-10" type="text" size="mini" @click="enterFolder(scope.row)">进入</el-button>
            <el-popconfirm
              :title="scope.row.type == 'folder' ? '将会删除该目录及其目录下所有文件，请确认？' : '删除后将不可恢复，请确认？'"
              @confirm="deleteFile(scope.row)"
            >
              <el-button slot="reference" type="text" size="mini">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div v-if="currentDir && list.length == 0" class="file-empty">
        <span>当前目录暂无文件</span>
      </div>
      <div v-else v-for="(item, index) in list" :key="index" class="file-item">
        <span>{{item.name}}</span>
        <div class="file-right">
          <el-button type="text" size="mini" @click="downLoad(item)">下载</el-button>
        </div>
      </div> -->
    </div>
    <el-dialog
      title="新建目录"
      append-to-body
      :visible.sync="dialogVisible"
      width="30%">
      <el-input v-model="addFolderName" placeholder="请输入新建目录名"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddFolder">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="文件重命名"
      append-to-body
      :visible.sync="renameDialog"
      width="30%">
      <el-input v-model="newFileName" placeholder="请输入文件名"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitrenameFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      user: '',
      currentDir: '',
      list: [],
      dialogVisible: false,
      addFolderName: '',
      renameFile: {},
      newFileName: '',
      renameDialog: false,
    }
  },
  created() {
    if (this.$store.state.userInfo && this.$store.state.userInfo.account) {
      this.currentDir = this.$store.state.userInfo.account
      this.getList()
    } else {
      this.$message.error('登录已失效，请重新登录')
      this.$router.push('/login')
    }
  },
  methods: {
    getList() {
      this.$emit('loadChange', true)
      this.list = []
      this.$api.post('file/list', {dir: this.currentDir}).then(res => {
        this.$emit('loadChange', false)
        if (res.res.status == 200) {
          // res.objects.forEach(e => {
          //   e.name = e.name.split("/")[1]
          //   e.lastModified = moment(new Date(e.lastModified)).format('YYYY-MM-DD HH:mm')
          // })
          let prefixes = res.prefixes ? res.prefixes.map(m => {
            return { 
              type: 'folder',
              name: m,
              fileName: m.replace(this.currentDir, '').replaceAll('/', '') 
            }
          }) : []
          let objectList = res.objects.filter(f => f.name != this.currentDir + '/')
          let objects = objectList.map(m => {
            m.type = 'file'
            m.fileName = m.name.slice(this.currentDir.length+1)
            m.lastModified = moment(new Date(m.lastModified)).format('YYYY-MM-DD HH:mm')
            return m
          })
          this.list = prefixes.concat(objects)
        } else {
          this.$message.error('查询失败')
        }
      })
    },
    progress() {
      this.$emit('loadChange', true)
    },
    beforeAvatarUpload(file) {
      const isLt1G = file.size / 1024 / 1024 / 1024 < 5;
      if (!isLt1G) {
        this.$message.error('大小不能超过5GB!');
      }
      return isLt1G;
    },
    putSuccess(res) {
      this.$emit('loadChange', false)
      if (res.status == 200) {
        this.$message.success('成功上传“'+res.data.directory+'”')
        this.getList()
      } else {
        this.$notify.error({
          title: '上传失败',
          message: '上传失败'
        });
      }
    },
    downLoad(file) {
      const downLoadUrl = file.url.replace('fileoss.qinxiang.cc', 'qin-file.oss-cn-beijing.aliyuncs.com')
      window.open(downLoadUrl)
    },
    addFolder() {
      this.dialogVisible = true
    },
    submitAddFolder() {
      if (!this.addFolderName) {
        this.$message.error('新建目录名不能为空')
        return
      }
      this.$emit('loadChange', true)
      this.$api.post('file/addFolder', { name: this.currentDir + '/' +this.addFolderName }).then(res => {
        if (res.res.status == 200) {
          this.dialogVisible = false
          this.addFolderName = ''
          this.getList()
        } else {
          this.$emit('loadChange', false)
          this.$message.error('新建目录失败')
        }
      })
    },
    deleteFile(row) {
      this.$emit('loadChange', true)
      if (row.type == 'folder') {
        this.$api.post('file/deleteFolder', { name: row.name }).then((res => {
          this.getList()
        }))
      } else {
        this.$api.post('file/delete', { fileDir: row.name }).then((res => {
          this.getList()
        }))
      }
    },
    rename(row) {
      this.renameFile = row
      this.newFileName = row.fileName
      this.renameDialog = true
    },
    submitrenameFile() {
      if (!this.newFileName) {
        this.$message.error('文件名不能为空')
        return
      }
      let oldName = this.renameFile.name
      let newName = this.renameFile.name.replace(this.renameFile.fileName, this.newFileName)
      this.$emit('loadChange', true)
      this.$api.post('file/renameFile', { oldName, newName }).then(res => {
        if (res.status == 200) {
          this.renameDialog = false
          this.getList()
        } else {
          this.$emit('loadChange', false)
          this.$message.error('文件重命名失败')
        }
      })
    },
    returnBack() {
      const lastIndex = this.currentDir.lastIndexOf('/')
      if (lastIndex == -1) {
        this.$message.warning('没有上一级了')
      } else {
        this.currentDir = this.currentDir.slice(0, lastIndex)
        this.getList()
      }
    },
    enterFolder(row) {
      this.currentDir = this.currentDir+ '/' + row.fileName
      this.getList()
    }
  },
  computed: {
    uploadData() {
      return {
        catalog: this.currentDir
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.dir {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.in-btn {
  margin-top: 10px;
}
.file-list {
  flex: 1;
  position: relative;
  overflow-y: auto;
}
.file-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.btn-group {
  display: flex;
  align-items: center;
  padding: 5px 0;
  // border-bottom: 1px solid #ccc;
}
.m-r-10 {
  margin-right: 10px;
}
</style>