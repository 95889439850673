import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home'
import login from '../views/login/login'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', name: 'home', component: home },
  { path: '/login', name: 'login', component: login }
]

const router = new VueRouter({
  routes
})

export default router
