<template>
  <div class="login">
    <img class="bg-img" :src="bgSrc" width="100%" height="100%"/>
    <div class="form">
      <div class="form-header">
        <span>QQMESSI</span>
        <span class="fs-style">FS</span>
      </div>
      <el-form v-if="type == 'login'" ref="login" :model="login" :rules="rules">
        <el-form-item>
          <span style="color: #fff">测试账号：test  密码：test1234</span>
        </el-form-item>
        <el-form-item prop="account">
          <el-input class="form-line" v-model="login.account" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="form-line" v-model="login.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <div class="line-group">
          <el-link type="warning" @click="typeChange">我要注册</el-link>
          <!-- <el-link type="warning" @click="showProcess">查看注册审核</el-link> -->
        </div>
        <el-button v-loading="submitLoading" class="submit-btn" @click="loginSubmit">登录</el-button>
      </el-form>
      <el-form v-else ref="register" :model="register" :rules="rules">
        <el-form-item prop="account">
          <el-input class="form-line" v-model="register.account" placeholder="请输入账号(英文或数字,例如:zhangsan)"></el-input>
        </el-form-item>
        <el-form-item prop="userName">
          <el-input class="form-line" v-model="register.userName" placeholder="请输入姓名，方便管理员进行审核"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input class="form-line" v-model="register.email" placeholder="请输入邮箱，用于接收审核结果以及系统消息"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input class="form-line" v-model="register.code" placeholder="请输入邮箱验证码">
            <el-button slot="append" v-loading="codeLoading" type="primary" @click="getCode" :disabled="countdown != 0">{{countdown==0?'获取验证码':countdown+'s后重试'}}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="form-line" v-model="register.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="checkPassword">
          <el-input class="form-line" v-model="register.checkPassword" placeholder="请再次输入密码" show-password></el-input>
        </el-form-item>
        <div class="line-group">
          <el-link type="warning" @click="typeChange">返回登录</el-link>
          <!-- <el-link type="warning" @click="showProcess">查看注册审核</el-link> -->
        </div>
        <el-button v-loading="submitLoading" class="submit-btn" @click="registerSubmit">注册</el-button>
      </el-form>
    </div>
    <el-link class="icp" type="info" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2021023557号-2</el-link>
  </div>
</template>

<script>
export default {
  data() {
    var checkPassRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.register.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      bgSrc: 'https://fileoss.qinxiang.cc/system/loginBg.jpg',
      type: 'login',
      submitLoading: false,
      login: {
        account: '',
        password: '',
      },
      register: {
        account: '',
        userName: '',
        email: '',
        code: '',
        password: '',
        checkPassword: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        userName: [{ required: true, message: '请输入姓名，方便管理员进行审核', trigger: 'blur' }],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        code: [{ required: true, message: '请输入邮箱验证码', trigger: 'blur' }],
        checkPassword: [
          { validator: checkPassRule, trigger: 'blur' },
        ]
      },
      countdown: 0,
      codeLoading: false,
      countInterval: null
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown)
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false)
  },
  methods: {
    loginSubmit() {
      this.$refs.login.validate((checkResult) => {
        if (!checkResult) {
          this.$message.error('请填写正确的账号和密码')
          return
        }
        this.submitLoading = true
        this.$api.post('user/login', { form: this.login }).then(res => {
          if (res.data) {
            this.$store.dispatch('set_userInfo', res.data)
          }
          if (res.code == 0) {
            this.$router.push('/')
          } else {
            this.$message.error(res.msg || '出错啦')
          }
          this.submitLoading = false
        })
      })
    },
    registerSubmit() {
      this.$refs.register.validate((checkResult) => {
        if (!checkResult) {
          this.$message.error('请先完善注册信息')
          return
        }
        this.submitLoading = true
        this.$api.post('user/apply', { form: this.register }).then(res => {
          this.$message({
            message: res.msg || '出错啦',
            type: res.code == 0 ? 'success' : 'error'
          })
          this.submitLoading = false
        })
      })
    },
    showProcess() {},
    typeChange() {
      this.type = this.type == 'login' ? 'register' : 'login'
      this.$refs.login && this.$refs.login.clearValidate()
      this.$refs.register && this.$refs.register.clearValidate()
    },
    getCode() {
      this.$refs.register.validateField('email', (checkRes) => {
        if (!checkRes) {
          this.codeLoading = true
          this.$api.post('user/getCode', {email: this.register.email}).then(res => {
            this.codeLoading = false
            this.countdown = 60
            this.countInterval = setInterval(() => {
              this.countdown--
              if (this.countdown == 0) {
                clearInterval(this.countInterval)
              }
            }, 1000)
          })
        }
      })
    },
    keyDown(e) {
      if (e.keyCode == 13) {
        if (this.type == 'login') {
          this.loginSubmit()
        } else {
          this.registerSubmit()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .bg-img {
    position: absolute;
    width:100%;  
    height:100%;
  }
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(20px);
    padding: 40px;
    border-radius: 20px;
    width: 350px;
    max-width: 100%;
  }
  .form-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    letter-spacing: 2px;
    .fs-style {
      color: #20a0ff;
      margin-left: 10px;
    }
  }
  .form-line {
    margin-top: 20px;
    ::v-deep .el-input__inner {
      background: #d2a4c0;
      color: #fff;
      &::placeholder {
        color: #e0dede;
      }
    }
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .line-group {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  .submit-btn {
    width: 100%;
    background: #716c95;
    border: none;
    color: #fff;
    margin-top: 20px;
  }
  .icp {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    color: #fff;
  }
}
</style>